// import React, { useEffect, useState} from "react";
import React, { useEffect} from "react";
// import { Link, Route, Redirect, BrowserRouter as Router } from 'react-router-dom';
// import {isMobile, isAndroid, isIOS} from 'react-device-detect';


const RenderContent = () => {
    
    // console.log("device-detect.isMObile=>>>>>>>", isMobile);
    // console.log("device-detect.isAndroid=>>>>>>>", isAndroid);
    // console.log("device-detect.isIOS=>>>>>>>", isIOS);

    // const [url, seturl] = useState("");

    useEffect(()=>{
        const cms='https://master.d2h60xprhkglro.amplifyapp.com/user/login';

        window.location.replace(cms);


    },[]);

    return(
        <div>Redirecting to cms...</div>

        
    )
};

export default RenderContent;